<template>
<div>
    <Header></Header>
        <!--顶部banner开始-->
        <div class="aboutus-banner left"> <img src="~images/banner/guanyuwomen.jpg" width="100%" /> </div>

        <!--公司简介开始-->
        <div class="aboutus-introduce left">
                <div class="aboutus-introduce-content center clearfix">
                    <div class="aboutus-introduce-left left">
                        <h3>北京华创龙达建设工程有限公司</h3>
                        {{data}}
                    </div>

                    <div class="aboutus-introduce-right right"> <img src="~images/about.png" width="400"/> </div>
                </div>
        </div>

        <!--企业文化开始-->
        <div class="aboutus-wenhua width-bai left">
                <p class="about_titles">企业文化</p>
                <div class="aboutus-wenhua-content center"> 
                    <div class="aboutus-weihua-contents clearfix">
                        <div class="aboutus-wenhua-title">
                            <label style="margin-left:180px;">企业愿景</label>
                            <label>企业原则</label>
                            <label style="margin-left:260px;">企业宗旨</label>
                            <label>企业目标</label>
                        </div>
                        <div class="aboutus-wenhua-title clearfix" style="color:#666;padding-top:80px;">
                            <label style="margin-left:150px;margin-top:20px;font-size:16px;">我们是幸福永久的业界标杆</label>
                            <label style="margin-left:50px;font-size:16px;">合作经营 诚实守信 <br />精通业务 勇于开拓</label>
                            <label style="margin-left:185px;font-size:16px;">保安全 重信用 讲质量<br /> 抓进度 促环保</label>
                            <label style="margin-left:40px;font-size:16px;">提升员工在企业中的<br />幸福指数,完善企业系统,以<br />系统力量推动企业发展</label>
                        </div>
                    </div>
                </div>
        </div>
           
        <!--企业架构开始-->
        <div class="aboutus-framework width-bai left">
            <p class="about_titles" style="margin-top:100px;">企业架构</p>
            <div class="aboutus-framework-content center"> <img src="~images/jiagou.jpg" /> </div>
        </div>
            
        <!--发展历程开始-->
        <div class="aboutus-framework width-bai left">
            <p class="about_titles" style="margin-top:100px;">发展历程</p>
                <div class="aboutus-framework-content center">
                    <div class="aboutus-licheng-li left"><img src="~images/licheng/lc1.jpg" /></div>
                    <div class="aboutus-licheng-li left"><img src="~images/licheng/lc2.jpg" /></div>
                    <div class="aboutus-licheng-li left"><img src="~images/licheng/lc3.jpg" /></div>
                    <div class="aboutus-licheng-li left"><img src="~images/licheng/lc4.jpg" /></div>
                    <div class="aboutus-licheng-li left"><img src="~images/licheng/lc5.jpg" /></div>
                    <div class="aboutus-licheng-li left"><img src="~images/licheng/lc6.jpg" /></div>
                    <div class="aboutus-licheng-li left"><img src="~images/licheng/lc7.jpg" /></div>
                    <div class="aboutus-licheng-li left"><img src="~images/licheng/lc8.jpg" /></div>
                    <div class="aboutus-licheng-li left"><img src="~images/licheng/lc9.jpg" /></div>
                    <div class="aboutus-licheng-li left"><img src="~images/licheng/lc10.jpg" /></div>
                </div>
        </div>

        <!--管理制度开始-->
        <div class="aboutus-framework width-bai left">
            <p class="about_titles" style="margin-top:100px;">管理制度</p>
            <div class="aboutus-framework-content center"> <img src="~images/zhidu.jpg" /> </div>
        </div>

        <!--证件开始-->
        <div class="aboutus-framework width-bai left">
            <p class="about_titles">企业荣誉</p>
            <div class="aboutus-framework-content center"> <img src="~images/zhengshu.png" /> </div>
        </div>

        <div class="aboutus-framework width-bai left">
            <div class="aboutus-framework-content center"> <img src="~images/zhengshu2.png" /> </div>
        </div>

        <div class="left clearfix width-bai"> <Footer></Footer> </div>
</div>
</template>

<script>
import Header from '../layout/Header.vue'
import Footer from '../layout/Footer.vue'

export default { 
name:"",

data() {
      return {
          data:''
      }
},

components:{
  Header,
  Footer
},

mounted() {
  this.$axios.post(window.configs.svcProt + "://" + window.configs.svcIpPort + '/get_about_us_content.html?token=' + this.$store.state.token)
	.then(res => {
    this.data = res.data.result;
  });
},

}
</script>

<style scoped>
    .aboutus-licheng-li{width:237px;text-align:center;margin-left:25px;margin-right:25px;margin-top:20px;margin-bottom:20px;height:710px;overflow:hidden;box-shadow: 0px 2px 5px #cccccc;}
    .about_titles{width:1440px;margin:auto;line-height:20px;padding-left:10px;font-weight:bold;font-size:16px;margin-bottom:20px;border-left:5px solid rgb(242,151,0)}
    .aboutus-wenhua-title label{margin-left:120px;margin-right:50px;float:left;}
    .aboutus-wenhua-title{line-height:30px;padding-top:118px;font-size:18px;color:#fff;}
    .aboutus-weihua-contents{width:98%;margin-left:2%;height:410px;background:url('~images/about_bj_ling.png');}
    .aboutus-wenhua-content p{line-height:45px;font-size:16px;padding-left:50px;color:#333;}
    .aboutus-wenhua-content h2{line-height:50px;text-align:center;font-size:22px;color:#333;font-weight:500;padding-top:20px;}
    .aboutus-wenhua{margin-top:50px;}
    .aboutus-wenhua-content{width:1440px;height:410px;background:url('~images/about_bj.png');background-size:100% 100%;border-radius:5px;}
    .aboutus-wenhua{height:410px;}
    .aboutus-banner{width:100%;height:470px;}
    .aboutus-introduce{width:100%;min-height:300px;}
    .aboutus-introduce-content{width:1440px;min-height:300px;margin:auto;}
    .aboutus-introduce-left{width:60%;min-height:250px;font-size:16px;color:#666;line-height:35px;padding-left:20px;margin-top:30px;}
    .aboutus-introduce-left h3{line-height:100px;}
    .aboutus-introduce-right{width:400px;height:275px;overflow:hidden;margin-right:20px;border-radius:5px;box-shadow: 0px 2px 5px #cccccc;margin-top:150px;}
    .aboutus-introduce-right img{transition: all 2s;cursor:pointer;}
    .aboutus-introduce-right img:hover{transform: scale(1.2);}
    .aboutus-framework-content{width:1440px;}

</style>
